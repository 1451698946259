import ActionBar from '@/components/ActionBar';
import { useCustomerCurrency } from '@/composables/useCustomerCurrency';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'CreditsBar',
  setup() {
    const { credit } = useCustomerCredits();
    const route = useRoute();
    const { formatPrice } = useCustomerCurrency();

    const { t } = useI18n();
    const description = t('credits_bar.description');
    const title = computed<string>(() =>
      t('credits_bar.you_have_credits', {
        count: formatPrice(credit.value),
      })
    );
    const showCreditsBar = computed<boolean>(
      () => credit.value !== 0 && route.name === 'index'
    );
    return {
      description,
      showCreditsBar,
      title,
    };
  },
  render(): VNode | null {
    if (!this.showCreditsBar) return null;
    return (
      <ActionBar type="credits">
        <div class="text-content">
          <h2 class="title">{this.title}</h2>
          <p class="description">{this.description}</p>
        </div>
      </ActionBar>
    );
  },
});
