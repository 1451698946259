/**
 * Composable providing information about customer credit.
 *
 * @see useCreditsApi
 * @see useCurrentUser
 */
export default function useCustomerCredits() {
  const { getCreditBalance } = useCreditsApi();
  const { data: rawCredit } = getCreditBalance();
  const credit = computed<number>(() => rawCredit?.value?.total || 0);
  return { credit };
}
