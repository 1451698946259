import type { HTMLAttributes, PropType, VNode } from 'vue';

type ActionBarType =
  | 'activate-subscription'
  | 'credits'
  | 'default'
  | 'select-products'
  | 'temporary-payment';

export default defineComponent({
  name: 'ActionBar',
  props: {
    type: {
      default: 'default',
      type: String as PropType<ActionBarType>,
    },
    wrapperClasses: {
      default: undefined,
      type: Object as PropType<HTMLAttributes['class']>,
    },
  },
  render(): VNode | null {
    return (
      <div class="action-bar" data-type={this.type}>
        <div class={['action-bar-content-wrapper', this.wrapperClasses]}>
          {this.$slots.default?.()}
        </div>
      </div>
    );
  },
});
