import ActivateSubscriptionBar from '@/components/ActivateSubscriptionBar';
import CreditsBar from '@/components/CreditsBar';
import SelectProductsBar from '@/components/SelectProductsBar';
import TemporaryPaymentProblemBar from '@/components/TemporaryPaymentProblemBar/TemporaryPaymentProblemBar';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'StickyBars',
  render(): VNode {
    return (
      <div class="sticky-bars">
        {/* Show IF the subscription is suspended. Show on EVERY route. */}
        <ActivateSubscriptionBar />

        {/* Show IF credits > 0 AND renewal is not blocked.
            Show ONLY on route '/'. */}
        <CreditsBar />

        {/* Show IF the payment has failed temporarily (insufficient funds)
            but NOT permanently. Show on EVERY route. */}
        <TemporaryPaymentProblemBar />

        {/* Show ONLY on routes 'recipes' AND 'additional-products'. */}
        <SelectProductsBar />
      </div>
    );
  },
});
