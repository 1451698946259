import IconArrowRight from '@/assets/icons/icon-arrow-right.svg?component';
import ActionBar from '@/components/ActionBar';
import useModalStore, {
  ModalType,
  OfferModalType,
} from '@/stores/useModalStore';
import { PlainWeek, Week, isCampaignActive } from '@ruokaboksi/utils';
import type { VNode } from 'vue';
import Button from '../Button';

export default defineComponent({
  name: 'ActivateSubscriptionBar',
  setup() {
    const route = useRoute();
    const { isFetchedSubscriptions, isSubscriptionSuspended, subscription } =
      useCustomerSubscriptions();
    const { campaignLink, campaignStartWeek } = useCustomerCampaigns();

    const modalStore = useModalStore();

    const hideActivateSubscriptionBar = computed<boolean>(
      () =>
        route.name?.toString().startsWith('onboarding') ||
        route.name?.toString().startsWith('delivery-calendar') ||
        route.name?.toString().startsWith('payment-required') ||
        route.name?.toString().startsWith('pay-delivery') ||
        !isFetchedSubscriptions.value ||
        !isSubscriptionSuspended.value
    );

    const onClick = () => {
      sessionStorage.removeItem('activateCalendarModalClosed');
      modalStore.openModal(ModalType.Offer, {
        offerType: OfferModalType.ActiveCalendarModal,
      });
    };

    const isCampaignActiveInFuture = computed(() => {
      if (!campaignLink.value || !subscription.value) return false;

      const campaignActiveNextWeek = isCampaignActive(
        campaignLink.value,
        Week.fromFirstFuturePaymentDate(subscription.value.defaultDeliverySlot)
      );
      return (
        campaignActiveNextWeek ||
        (campaignStartWeek.value &&
          new PlainWeek(
            campaignStartWeek.value?.isoYear,
            campaignStartWeek.value?.isoWeek
          ).isInFuture())
      );
    });

    return {
      isCampaignActiveInFuture,
      isFetchedSubscriptions,
      isSubscriptionSuspended,
      hideActivateSubscriptionBar,
      onClick,
    };
  },
  render(): VNode | null {
    if (this.hideActivateSubscriptionBar) return null;
    return (
      <ActionBar type="activate-subscription">
        <div class="text-content">
          {this.$t('activate_subscription.heading')}
        </div>
        <Button onClick={this.onClick} class={['button', 'button-primary']}>
          {this.isCampaignActiveInFuture
            ? this.$t('activate_subscription.button_text_campaign')
            : this.$t('activate_subscription.button_text')}
          <IconArrowRight height="24" width="24" class="icon" />
        </Button>
      </ActionBar>
    );
  },
});
