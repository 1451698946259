import type { VNode } from 'vue';

export default defineComponent({
  name: 'MainFooter',
  render(): VNode | null {
    if (!this.$slots.default) return null;
    return (
      <footer class="container mx-auto pb-20 md:pb-0">
        {this.$slots.default()}
      </footer>
    );
  },
});
